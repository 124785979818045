import * as TYPE from './types'

// CRUD
const dispatchEventCreateAsync = (data) => ({
  type: TYPE.EVENT_CREATE,
  data,
});
const dispatchEventListAsync = (data) => ({
  type: TYPE.EVENT_LIST,
  data,
});
const dispatchEventUpdateAsync = (data) => ({
  type: TYPE.EVENT_UPDATE,
  data,
});
const dispatchEventDeleteAsync = (data) => ({
  type: TYPE.EVENT_DELETE,
  data,
});

// detail
const dispatchEventGetDetailAsync = (data) => ({
  type: TYPE.EVENT_GET_DETAIL,
  data,
});

// points accounts
const dispatchEventGetPointsAccountAsync = (data) => ({
  type: TYPE.EVENT_GET_POINTS_ACCOUNTS,
  data,
});

// analytics
// 1
const dispatchEventGetTotalCountAsync = (data) => ({
  type: TYPE.EVENT_GET_TOTAL_COUNT,
  data,
});
// 2
const dispatchEventGetGiftDayDonatedAsync = (data) => ({
  type: TYPE.EVENT_GET_GIFT_DAY_DONATED,
  data,
});
// 3.1
const dispatchEventGetPointGiftAsync = (data) => ({
  type: TYPE.EVENT_GET_POINT_GIFT,
  data,
});
// 3.3
const dispatchEventGetPointGiftImportTodayAsync = (data) => ({
  type: TYPE.EVENT_GET_POINT_GIFT_IMPORT_TODAY,
  data,
});
//3.2 - 4 - 5
const dispatchEventGetPointCustomerAsync = (data) => ({
  type: TYPE.EVENT_GET_POINT_CUSTOMER,
  data,
});
// 6
const dispatchEventGetGiftHourDonatedAsync = (data) => ({
  type: TYPE.EVENT_GET_GIFT_HOUR_DONATED,
  data,
});
// 7
const dispatchEventGetCustomersAsync = (data) => ({
  type: TYPE.EVENT_GET_CUSTOMERS,
  data,
});
const dispatchEventDownloadCsvCustomersAsync = (data) => ({
  type: TYPE.EVENT_DOWNLOAD_CSV_CUSTOMERS,
  data,
});
// 8
const dispatchEventGetPointsGiftsAsync = (data) => ({
  type: TYPE.EVENT_GET_POINTS_GIFTS,
  data,
});

export default {
  dispatchEventCreateAsync,
  dispatchEventListAsync,
  dispatchEventUpdateAsync,
  dispatchEventDeleteAsync,

  dispatchEventGetDetailAsync,
  dispatchEventGetPointsAccountAsync,

  // analytics
  dispatchEventGetTotalCountAsync,
  dispatchEventGetGiftDayDonatedAsync,
  dispatchEventGetPointGiftAsync,
  dispatchEventGetPointGiftImportTodayAsync,
  dispatchEventGetPointCustomerAsync,
  dispatchEventGetGiftHourDonatedAsync,
  dispatchEventGetCustomersAsync,
  dispatchEventDownloadCsvCustomersAsync,
  dispatchEventGetPointsGiftsAsync,
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.EVENT_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.EVENT_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.EVENT_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.EVENT_DELETE_ASYNC,
    payload
  }
}

// detail
export const GetDetailAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_DETAIL_ASYNC,
    payload
  }
}

// points accounts
export const GetPointsAccountsAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_POINTS_ACCOUNTS_ASYNC,
    payload
  }
}

// analytics
//1
export const GetTotalCountAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_TOTAL_COUNT_ASYNC,
    payload
  }
}
//2
export const GetGiftDayDonatedAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_GIFT_DAY_DONATED_ASYNC,
    payload
  }
}
//3.1
export const GetPointGiftAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_POINT_GIFT_ASYNC,
    payload
  }
}
//3.3
export const GetPointGiftImportTodayAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_POINT_GIFT_IMPORT_TODAY_ASYNC,
    payload
  }
}
//3.2 - 4 - 5
export const GetPointCustomerAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_POINT_CUSTOMER_ASYNC,
    payload
  }
}
// 6
export const GetGiftHourDonatedAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_GIFT_HOUR_DONATED_ASYNC,
    payload
  }
}
//7
export const GetCustomersAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_CUSTOMERS_ASYNC,
    payload
  }
}
export const DownloadCsvCustomersAsync = (payload) => {
  return {
    type: TYPE.EVENT_DOWNLOAD_CSV_CUSTOMERS_ASYNC,
    payload
  }
}
//8
export const GetPointsGiftsAsync = (payload) => {
  return {
    type: TYPE.EVENT_GET_POINTS_GIFTS_ASYNC,
    payload
  }
}