import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncGiftsList(data) {
  const { data: {  locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/manager/point/gifts-users?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetCustomers(data) {
  const { data: { users_uuid, date_start, date_end, number_gifts_received, locale } } = data
  return axios.post(Url.END_POINT + "/api/export/manager/list-customers?locale=" + locale,
    { users_uuid, date_start, date_end, number_gifts_received, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetAccountsStaff(data) {
  const { data: { locale } } = data
  return axios.get(Url.END_POINT + "/api/point/manager/users-staff?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

function asyncDownloadCsvCustomers(data) {
  const { data: { event_uuid, users_uuid, date_start, date_end, number_gifts_received, locale } } = data
  return axios.post(Url.END_POINT + "/api/export/manager/customers/",
    { event_uuid, users_uuid, date_start, date_end, number_gifts_received, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionGiftsList(data) {
  const response = yield call(asyncGiftsList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification } = response.data;
      result.data = Notification;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAsync(result))
}
function* actionGetCustomers(data) {
  const response = yield call(asyncGetCustomers, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Customers } } = response.data;
      result.data = Customers;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetCustomersAsync(result))
}
function* actionGetAccountsStaff(data) {
  const response = yield call(asyncGetAccountsStaff, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Accounts } } = response.data;
      result.data = Accounts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetAccountsStaffAsync(result))
}
function* actionDownloadCsvCustomers(data) {
  const response = yield call(asyncDownloadCsvCustomers, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Message } } = response.data;
      result.data = Message;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.DownloadCsvCustomersAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.GIFT_MANAGER_LIST, actionGiftsList)
  yield takeLatest(TYPE.EVENT_MANAGER_GET_ACCOUNTS_STAFF, actionGetAccountsStaff)
  yield takeLatest(TYPE.EVENT_MANAGER_GET_CUSTOMERS, actionGetCustomers)
  yield takeLatest(TYPE.EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS, actionDownloadCsvCustomers)

}

export default CustomSaga