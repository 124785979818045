import * as TYPE from './types'
import { 
  WsInitState,
  WsRegister
} from '../../helpers/SocketIO';
import { CurrentTimeStamp } from '../../helpers/DateTime';

const INITIAL_STATE = {
  socket: WsInitState(),
  socket_uuid: CurrentTimeStamp(),
};

const intlData = (state = INITIAL_STATE, action) => {
  if (action === undefined) return state;
  switch (action.type) {
    case TYPE.INIT_REALTIME:
      return {
        socket: INITIAL_STATE.socket,
        socket_uuid: INITIAL_STATE.socket_uuid,
        register: WsRegister(INITIAL_STATE.socket)
      };
    case TYPE.UPDATE_REALTIME:
      return {
        ...state,
        socket_uuid: CurrentTimeStamp(),
      };
    default:
      return state;
  }
};

export default intlData;