import React from 'react'
import ReactDOM from 'react-dom'

// redux
import rootReducer from './redux'
import rootSaga from './redux/saga'

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { BrowserRouter } from 'react-router-dom'

import 'react-perfect-scrollbar/dist/css/styles.css'
import "dropzone/dist/min/dropzone.min.css"
import 'react-toastify/dist/ReactToastify.min.css'
import 'rsuite/dist/styles/rsuite-default.css';
import 'leaflet/dist/leaflet.css'
import '../scss/app.scss'

import App from './App'

import { toast } from 'react-toastify';
toast.configure({
  autoClose: 3000,
  draggable: true,
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// mount it on the Store
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
)

// Run the saga
sagaMiddleware.run(rootSaga)

import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</Provider>)

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>, document.getElementById('app')
// );
