import React, { useState, useEffect } from 'react';
import { ConnectedComponent as connect } from '../../constants'
import { NavLink, Link } from 'react-router-dom'
import {
  Dropdown,
  Icon,
} from 'rsuite'
import { toast } from 'react-toastify'
import Profile from './Profile'
import { isPermissionAdmin } from '../../helpers/Utils';

const languages = [
  {
    data: "vi",
    label: "🇻🇳",
    panel: true,
  },
  {
    data: "en",
    label: "🇬🇧",
    panel: false,
  },
]

const Toolbar = props => {
  const [state, setState] = useState({
    open_dropdown_language: false,
    open_drawer: false,
    is_submit: false,
    profile_form: {
      full_name: "",
      avatar_img_path: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    }
  })

  /** SocketIO */
  useEffect(
    () => {
      props.dispatchInitRealtime();
    }, []);
  useEffect(() => {

    // Checkin
    props.realtime.socket.on('checkin', (data) => {
      // console.log("checkin");

      props.dispatchAccountListAsync({
        uuid: localStorage.getItem("event_uuid"),
        locale: props.i18n.locale,
      });
    })

    // Checkout
    props.realtime.socket.on('checkout', (data) => {
      // console.log("checkout");

      props.dispatchAccountListAsync({
        uuid: localStorage.getItem("event_uuid"),
        locale: props.i18n.locale,
      });
    })

    // Check Distance
    props.realtime.socket.on('checkDistance', (data) => {
      // console.log("checkDistance data: ", data);

      // alert to admin
      if (data.is_show == "true") {
        toast.warn(data.text, {
          autoClose: false
        })
      }
    })

    // Create Customer
    props.realtime.socket.on('createCustomer', (data) => {
      // console.log("createCustomer data: ", data);
      props.dispatchUpdateRealtime();
    })

    // Leftover gift down
    props.realtime.socket.on('leftoverGiftDown', (data) => {
      // console.log("leftoverGiftDown data: ", data);

      // alert to admin
      if (data.is_show == "true") {
        toast.warn(data.text, {
          autoClose: false
        })
      }
    })
  }, [props.realtime.socket]);
  /** END SocketIO */

  useEffect(
    () => {
      if (state.is_submit) {

        // 
        if (props.accounts.messageUpdateMe && props.accounts.messageUpdateMe.length) {
          if (props.accounts.statusUpdateMe === 200) {
            toast.info(props.accounts.messageUpdateMe);
          } else {
            toast.error(props.accounts.messageUpdateMe);
          }
        }
      }
    }, [props.accounts.messageUpdateMe, state.is_submit])
  useEffect(() => {
    if (props.accounts.data_user) {
      // console.log(props.accounts.data_user);

      let { full_name, avatar_img_path } = props.accounts.data_user;

      full_name = full_name || localStorage.getItem('full_name');
      avatar_img_path = avatar_img_path || localStorage.getItem('avatar_img_path');

      setState({
        ...state,
        profile_form: {
          ...state.profile_form,
          full_name,
          avatar_img_path,
        }
      })
    }
  }, [props.accounts.data_user])

  const handleOnClickLogout = e => {
    props.dispatchLogoutAsync();
  }

  // Drawer
  const onHandleToggleDrawer = () => {
    if (state.open_drawer) {
      // close
      setState({
        ...state,
        open_drawer: !state.open_drawer
      })
    }
    else {
      // open -> update
      setState({
        ...state,
        open_drawer: !state.open_drawer,
        profile_form: {
          ...state.profile_form,
          full_name: state.profile_form.full_name || localStorage.getItem('full_name'),
          avatar_img_path: state.profile_form.avatar_img_path || localStorage.getItem('avatar_img_path'),
        }
      })
    }
  }

  const onSubmitDrawer = (type) => {

    if (type === 'update') {

      // validate
      if (!state.profile_form.full_name) {
        return toast.error(props.i18n.messages['required'])
      }

      // required change password
      if (state.profile_form.password.length) {
        // required old password
        if (!state.profile_form.oldPassword.length) {
          return toast.error(props.i18n.messages['required'])
        }

        // min 6
        if (state.profile_form.password.length < 6) {
          return toast.error(props.i18n.messages['required.min-password'])
        }

        // confirm password
        if (state.profile_form.password != state.profile_form.confirmPassword) {
          return toast.error(props.i18n.messages['required.match-password'])
        }
      }

      // update
      let avatar_img_path = state.profile_form.avatar_img_path;

      // avatar_img_path
      if (!state.profile_form.avatar_img_path) {
        let images = localStorage.getItem("uploads");
        if (!images) {
          return toast.error(props.i18n.messages['required']);
        }
        else {
          avatar_img_path = images;
        }
      }

      props.dispatchAccountUpdateMeAsync({
        full_name: state.profile_form.full_name,
        image: avatar_img_path,
        oldPassword: state.profile_form.oldPassword,
        password: state.profile_form.password,
        confirmPassword: state.profile_form.confirmPassword,
        locale: props.i18n.locale,
      });

      setState({
        ...state,
        open_drawer: !state.open_drawer,
        is_submit: true,
      })
    }
    if (type === 'remove-image') {
      setState({
        ...state,
        profile_form: {
          ...state.profile_form,
          avatar_img_path: "",
        }
      })
      return "";
    }

    localStorage.removeItem('uploads')
    return "";
  }

  const onChangeDrawer = (val, e) => {
    const { target: { name, value } } = e;

    setState({
      ...state,
      is_submit: false,
      profile_form: {
        ...state.profile_form,
        [name]: value
      }
    })
  }
  // End Drawer

  let routerTo = "/app/dashboards/default";
  if (isPermissionAdmin()) {
    routerTo = "/app/dashboards/supervisor"
  }

  return (
    <nav className="toolbar__navigation">
      <Link className="side-drawer-logo" to={routerTo} />
      <div className="spacer" />
      <div className="login-container__language">
        <Dropdown
          onToggle={e => {
            setState({
              ...state,
              open_dropdown_language: !state.open_dropdown_language
            })
          }}
          title={languages.filter(l => l.data === props.i18n.locale)[0].label}
          open={state.open_dropdown_language}
        >
          {languages.map(lang => <span key={lang.data}>
            <Dropdown.Item
              style={{ width: 50, fontSize: 25 }}
              onClick={e => {
                setState({
                  ...state,
                  open_dropdown_language: !state.open_dropdown_language
                })
                props.dispatchUpdateLanguage(lang.data)
              }}>
              {lang.label}
            </Dropdown.Item>
            {lang.panel ? <Dropdown.Item divider /> : null}
          </span>
          )}
        </Dropdown>
      </div>
      <div className="toolbar_navigation-items">
        <Profile
          i18n={props.i18n}
          open={state.open_drawer}
          toggle={onHandleToggleDrawer}
          onSubmit={onSubmitDrawer}
          onChange={onChangeDrawer}
          values={state.profile_form}
        />
        <Dropdown title={localStorage.getItem('full_name') || localStorage.getItem('user_name')} icon={<Icon icon="cog" />}>
          <Dropdown.Item
            onClick={onHandleToggleDrawer}
            style={{ minWidth: 160 }} icon={<Icon icon="user-circle-o" />}>
            {props.i18n.messages['toolbar.profile']}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleOnClickLogout}
            style={{ minWidth: 160 }} icon={<Icon icon="exit" />}>
            {props.i18n.messages['toolbar.logout']}
          </Dropdown.Item>
        </Dropdown>
      </div>
    </nav>
  )
}

export default connect(Toolbar)
