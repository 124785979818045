import * as TYPE from './types'

// CRUD
const dispatchGiftManagerListAsync = (data) => ({
  type: TYPE.GIFT_MANAGER_LIST,
  data,
});
const dispatchEventManagerGetCustomersAsync = (data) => ({
  type: TYPE.EVENT_MANAGER_GET_CUSTOMERS,
  data,
});
const dispatchEventManagerGetAccountsStaffAsync = (data) => ({
  type: TYPE.EVENT_MANAGER_GET_ACCOUNTS_STAFF,
  data,
});
const dispatchEventManagerDownloadCsvCustomersAsync = (data) => ({
  type: TYPE.EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS,
  data,
});

export default {
  dispatchGiftManagerListAsync,
  dispatchEventManagerGetCustomersAsync,
  dispatchEventManagerGetAccountsStaffAsync,
  dispatchEventManagerDownloadCsvCustomersAsync,
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.GIFT_MANAGER_LIST_ASYNC,
    payload
  }
} 
export const GetCustomersAsync = (payload) => {
  return {
    type: TYPE.EVENT_MANAGER_GET_CUSTOMERS_ASYNC,
    payload
  }
} 
export const GetAccountsStaffAsync = (payload) => {
  return {
    type: TYPE.EVENT_MANAGER_GET_ACCOUNTS_STAFF_ASYNC,
    payload
  }
} 
export const DownloadCsvCustomersAsync = (payload) => {
  return {
    type: TYPE.EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS_ASYNC,
    payload
  }
} 
