import * as TYPE from './types'

const setLanguage = language => {
    let messages = {};
    localStorage.setItem('locale', language)

    switch (language) {
      case 'vi':
        messages = Object.assign(messages, require(`./data/vn.json`));
        break;
      default:
      case 'en':
        messages = Object.assign(messages, require(`./data/en.json`));
        break;
    }
    return messages;
};

const INITIAL_STATE = {
  locale: localStorage.getItem('locale') || 'vi',
  messages: setLanguage(localStorage.getItem('locale') || 'vi')
};

const intlData = (state = INITIAL_STATE, action) => {
  if (action === undefined) return state;
  switch (action.type) {
    case TYPE.UPDATE_LANGUAGE:
      return {
        locale: action.language,
        messages: setLanguage(action.language)
      };
    default:
      return state;
  }
};

export default intlData;