import * as TYPE from './types'

const dispatchLoginAsync = (data, history) => ({
  type: TYPE.ACCOUNT_LOGIN,
  data,
  history,
});
const dispatchLogoutAsync = (history) => ({
  type: TYPE.ACCOUNT_LOGOUT,
  history,
});
const dispatchAccountCreateAsync = (data) => ({
  type: TYPE.ACCOUNT_CREATE,
  data,
});
const dispatchAccountMultiCreateAsync = (data) => ({
  type: TYPE.ACCOUNT_MULTI_CREATE,
  data,
});
const dispatchAccountUpdateAsync = (data) => ({
  type: TYPE.ACCOUNT_UPDATE,
  data,
});
const dispatchAccountDeleteAsync = (data) => ({
  type: TYPE.ACCOUNT_DELETE,
  data,
});
const dispatchAccountListAsync = (data) => ({
  type: TYPE.ACCOUNT_LIST,
  data,
});

// me
const dispatchAccountUpdateMeAsync = (data) => ({
  type: TYPE.ACCOUNT_UPDATE_ME,
  data,
});

// avaliable
const dispatchAccountListAvaliableAsync = (data) => ({
  type: TYPE.ACCOUNT_LIST_AVALIABLE,
  data,
});

// notification 
const dispatchAccountListNotificationAsync = (data) => ({
  type: TYPE.ACCOUNT_LIST_NOTIFICATION,
  data,
});
const dispatchAccountSendNotificationAsync = (data) => ({
  type: TYPE.ACCOUNT_SEND_NOTIFICATION,
  data,
});

//  
const dispatchAccountUnmountAsync = (data) => ({
  type: TYPE.ACCOUNT_UNMOUNT,
  data,
});

// link game
const dispatchAccountLinkGameUpdateAsync =(data) => ({
  type: TYPE.ACCOUNT_LINK_GAME_UPDATE,
  data,
})

export default {
  dispatchLoginAsync,
  dispatchLogoutAsync,

  dispatchAccountCreateAsync,
  dispatchAccountMultiCreateAsync,
  dispatchAccountUpdateAsync,
  dispatchAccountListAsync,
  dispatchAccountDeleteAsync,

  dispatchAccountUpdateMeAsync,
  dispatchAccountListAvaliableAsync,

  dispatchAccountListNotificationAsync,
  dispatchAccountSendNotificationAsync,

  dispatchAccountUnmountAsync,
  dispatchAccountLinkGameUpdateAsync,
};

export const LoginAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LOGIN_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_CREATE_ASYNC,
    payload
  }
}
export const MultiCreateAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_MULTI_CREATE_ASYNC,
    payload
  }
}
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_UPDATE_ASYNC,
    payload
  }
}
export const ListAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LIST_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_DELETE_ASYNC,
    payload
  }
}
export const UpdateMeAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_UPDATE_ME_ASYNC,
    payload
  }
}
export const ListAvaliableAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LIST_AVALIABLE_ASYNC,
    payload
  }
}
export const ListNotificationAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LIST_NOTIFICATION_ASYNC,
    payload
  }
}
export const SendNotificationAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_SEND_NOTIFICATION_ASYNC,
    payload
  }
}
export const LinkGameUpdateAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LINK_GAME_UPDATE_ASYNC,
    payload
  }
}