import * as TYPE from './types'

const initialState = {
  data: undefined,
  detail: {},
  data_updated: undefined,
  message: undefined,
  status: 500,
}

function PointReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.POINT_UNMOUNT_ASYNC:
      return initialState;
    case TYPE.POINT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.POINT_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.POINT_UPDATE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.POINT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }

    case TYPE.POINT_GET_DETAIL_ASYNC:
      const { id } = action.payload.data;

      let cloneDetail = state.detail;
      cloneDetail[id] = action.payload.data;

      /**
       * cloneDetail object
      {  
       {1: ...},
       {2: ...},
       {3: ...},
      }
       */

      // console.log(cloneDetail);

      return {
        ...state,
        detail: cloneDetail,
      }
    default:
      return state
  }
}

export default PointReducer