import * as TYPE from './types'

// CRUD
const dispatchPointCreateAsync = (data) => ({
  type: TYPE.POINT_CREATE,
  data,
});
const dispatchPointListAsync = (data) => ({
  type: TYPE.POINT_LIST,
  data,
});
const dispatchPointUpdateAsync = (data) => ({
  type: TYPE.POINT_UPDATE,
  data,
});
const dispatchPointDeleteAsync = (data) => ({
  type: TYPE.POINT_DELETE,
  data,
});

const dispatchPointGetDetailAsync = (data) => ({
  type: TYPE.POINT_GET_DETAIL,
  data,
});

const dispatchPointUnmountAsync = (data) => ({
  type: TYPE.POINT_UNMOUNT,
  data,
});

export default {
  dispatchPointCreateAsync,
  dispatchPointListAsync,
  dispatchPointUpdateAsync,
  dispatchPointDeleteAsync,

  dispatchPointGetDetailAsync,

  dispatchPointUnmountAsync,
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.POINT_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.POINT_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.POINT_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.POINT_DELETE_ASYNC,
    payload
  }
}

// detail
export const GetDetailAsync = (payload) => {
  return {
    type: TYPE.POINT_GET_DETAIL_ASYNC,
    payload
  }
}

// unmount
export const UnmountAsync = (payload) => {
  return {
    type: TYPE.POINT_UNMOUNT_ASYNC,
    payload
  }
}