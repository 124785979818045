// CRUD
export const EVENT_CREATE = "EVENT_CREATE"
export const EVENT_CREATE_ASYNC = "EVENT_CREATE_ASYNC"
export const EVENT_UPDATE = "EVENT_UPDATE"
export const EVENT_UPDATE_ASYNC = "EVENT_UPDATE_ASYNC"
export const EVENT_LIST = "EVENT_LIST"
export const EVENT_LIST_ASYNC = "EVENT_LIST_ASYNC"
export const EVENT_DELETE = "EVENT_DELETE"
export const EVENT_DELETE_ASYNC = "EVENT_DELETE_ASYNC"

// DETAIL
export const EVENT_GET_DETAIL = "EVENT_GET_DETAIL"
export const EVENT_GET_DETAIL_ASYNC = "EVENT_GET_DETAIL_ASYNC"

// POINTS ACCOUNTS
export const EVENT_GET_POINTS_ACCOUNTS = "EVENT_GET_POINTS_ACCOUNTS"
export const EVENT_GET_POINTS_ACCOUNTS_ASYNC = "EVENT_GET_POINTS_ACCOUNTS_ASYNC"

// ANALYTICS - CHART
//1 
export const EVENT_GET_TOTAL_COUNT = "EVENT_GET_TOTAL_COUNT"
export const EVENT_GET_TOTAL_COUNT_ASYNC = "EVENT_GET_TOTAL_COUNT_ASYNC"
//2
export const EVENT_GET_GIFT_DAY_DONATED = "EVENT_GET_GIFT_DAY_DONATED"
export const EVENT_GET_GIFT_DAY_DONATED_ASYNC = "EVENT_GET_GIFT_DAY_DONATED_ASYNC"
//3.1
export const EVENT_GET_POINT_GIFT = "EVENT_GET_POINT_GIFT"
export const EVENT_GET_POINT_GIFT_ASYNC = "EVENT_GET_POINT_GIFT_ASYNC"
//3.3
export const EVENT_GET_POINT_GIFT_IMPORT_TODAY = "EVENT_GET_POINT_GIFT_IMPORT_TODAY"
export const EVENT_GET_POINT_GIFT_IMPORT_TODAY_ASYNC = "EVENT_GET_POINT_GIFT_IMPORT_TODAY_ASYNC"
//3.2 - 4 - 5
export const EVENT_GET_POINT_CUSTOMER = "EVENT_GET_POINT_CUSTOMER"
export const EVENT_GET_POINT_CUSTOMER_ASYNC = "EVENT_GET_POINT_CUSTOMER_ASYNC"
//6
export const EVENT_GET_GIFT_HOUR_DONATED = "EVENT_GET_GIFT_HOUR_DONATED"
export const EVENT_GET_GIFT_HOUR_DONATED_ASYNC = "EVENT_GET_GIFT_HOUR_DONATED_ASYNC"
//7
export const EVENT_GET_CUSTOMERS = "EVENT_GET_CUSTOMERS"
export const EVENT_GET_CUSTOMERS_ASYNC = "EVENT_GET_CUSTOMERS_ASYNC"
export const EVENT_DOWNLOAD_CSV_CUSTOMERS = "EVENT_DOWNLOAD_CSV_CUSTOMERS"
export const EVENT_DOWNLOAD_CSV_CUSTOMERS_ASYNC = "EVENT_DOWNLOAD_CSV_CUSTOMERS_ASYNC"

// 8
export const EVENT_GET_POINTS_GIFTS = "EVENT_GET_POINTS_GIFTS"
export const EVENT_GET_POINTS_GIFTS_ASYNC = "EVENT_GET_POINTS_GIFTS_ASYNC"