import * as TYPE from './types'

const initialState = {
  data_gifts: [],
  data_customers: [],
  data_accounts_staff: [],
  data_customers_link_csv: null,
  message: "",
  status: 500,
}

function SupervisorReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.GIFT_MANAGER_LIST_ASYNC:
      return {
        ...state,
        data_gifts: action.payload.data,
      }
    case TYPE.EVENT_MANAGER_GET_CUSTOMERS_ASYNC:
      return {
        ...state,
        data_customers: action.payload.data,
      }
    case TYPE.EVENT_MANAGER_GET_ACCOUNTS_STAFF_ASYNC:
      return {
        ...state,
        data_accounts_staff: action.payload.data,
      }
    case TYPE.EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS_ASYNC:
      return {
        ...state,
        data_customers_link_csv: action.payload.data,
      }
    default:
      return state
  }
}

export default SupervisorReducer