const END_POINT = process.env.END_POINT || "https://apidata.stoo.vn"
const END_POINT_WS = process.env.END_POINT_WS || "wss://apidata.stoo.vn:6002"
const END_POINT_SOCKETIO = process.env.END_POINT_SOCKETIO || "wss://realtimedata.stoo.vn"
const END_POINT_IMAGE = process.env.END_POINT_IMAGE || "https://image.stoo.vn"

export default {
    END_POINT,
    END_POINT_WS,
    END_POINT_SOCKETIO,
    END_POINT_IMAGE,
}
