import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCreate(data) {
  const { data: { event_uuid, name, total, image, locale } } = data;
  return axios.post(Url.END_POINT + "/api/gift",
    { event_uuid, name, total, image, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/gifts/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: { id, name = "", total = "", image = "", locale } } = data;
  return axios.put(Url.END_POINT + "/api/gift",
    { id, name, total, image, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { id, locale } } = data;
  return axios.delete(Url.END_POINT + "/api/gift",
    {
      data: {
        id,
        locale,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListAvaliable(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/gifts-avaliable/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListByUser(data) {
  const { data: { user_uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/minigame/gift/" + user_uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncSuccessSubmit(data) {
  const { data: { user_uuid, gift_id, locale } } = data
  return axios.post(Url.END_POINT + "/api/minigame/gift/pending",
    {
      user_uuid, gift_id, locale
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncSpinCountByUser(data) {
  const { data: { user_uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/minigame/giftcount/" + user_uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification } = response.data;
      result.data = Notification;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // console.log(response); return;

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionListAvaliable(data) {
  const response = yield call(asyncListAvaliable, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Gifts } } = response.data;
      result.data = Gifts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAvaliableAsync(result))
}
function* actionListByUser(data) {
  const response = yield call(asyncListByUser, data);

  // console.log(response); //return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Gifts } } = response.data;
      result.data = Gifts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListByUserAsync(result))
}
function* actionSuccessSubmit(data) {
  const response = yield call(asyncSuccessSubmit, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.SuccessSubmitAsync(result))
}
function* actionSpinCountByUser(data) {
  const response = yield call(asyncSpinCountByUser, data);

  // console.log(response); //return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Count } } = response.data;
      result.data = Count;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.SpinCountByUserAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.GIFT_CREATE, actionCreate)
  yield takeLatest(TYPE.GIFT_LIST, actionList)
  yield takeLatest(TYPE.GIFT_LIST_AVALIABLE, actionListAvaliable)
  yield takeLatest(TYPE.GIFT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.GIFT_DELETE, actionDelete)
  yield takeLatest(TYPE.GIFT_LIST_BY_USER, actionListByUser)
  yield takeLatest(TYPE.GIFT_SUCCESS_SUBMIT, actionSuccessSubmit)
  yield takeLatest(TYPE.GIFT_SPIN_COUNT_BY_USER, actionSpinCountByUser)
}

export default CustomSaga