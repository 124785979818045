import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCreate(data) {
  const { data: { name, date_time_start, date_time_end, time_start, time_end, locale } } = data;
  return axios.post(Url.END_POINT + "/api/event",
    { name, date_time_start, date_time_end, time_start, time_end, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList(data) {
  const { data: { locale } } = data
  return axios.get(Url.END_POINT + "/api/events?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: { 
    uuid, 
    name = "", 
    active = "", 
    soft_deleted = "", 
    date_time_start = "", 
    date_time_end = "", 
    time_start = "", 
    time_end = "", 
    locale } } = data;
  return axios.put(Url.END_POINT + "/api/event",
    { uuid, name, active, soft_deleted, date_time_start, date_time_end, time_start, time_end, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { uuid, locale } } = data;
  return axios.delete(Url.END_POINT + "/api/event",
    {
      data: {
        uuid,
        locale,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetDetail(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/event/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetPointsAccounts(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/event/" + uuid + "/points/accounts?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

//analytics
function asyncGetTotalCount(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/event/" + uuid + "/dashboard?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetGiftDayDonated(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/event/" + uuid + "/gifts?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetPointGift(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/event/" + uuid + "/points-gifts?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetPointGiftImportToday(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/event/" + uuid + "/points-gifts-import-today?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetPointCustomer(data) {
  const { data: { uuid, locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/pie/" + uuid + "?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetGiftHourDonated(data) {
  const { data: { uuid, date, locale } } = data
  return axios.get(Url.END_POINT + "/api/chart/event/" + uuid + "/" + date + "/gifts?locale=" + locale,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetCustomers(data) {
  const { data: { event_uuid, users_uuid, date_start, date_end, number_gifts_received, locale } } = data
  
  return axios.post(Url.END_POINT + "/api/export/list-customers?locale=" + locale,
    { event_uuid, users_uuid, date_start, date_end, number_gifts_received, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDownloadCsvCustomers(data) {
  const { data: { event_uuid, users_uuid, date_start, date_end, number_gifts_received, locale } } = data
  return axios.post(Url.END_POINT + "/api/export/customers/",
    { event_uuid, users_uuid, date_start, date_end, number_gifts_received, locale },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetPointsGifts(data) {
  const { data: { uuid, locale, date_start, date_end } } = data
  return axios.get(`${Url.END_POINT}/api/chart/event/${uuid}/gifts-points/${date_start}/${date_end}?locale=${locale}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Events } } = response.data;
      result.data = Events;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // console.log(response); return;

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionGetDetail(data) {
  const response = yield call(asyncGetDetail, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Events } } = response.data;
      result.data = Events;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetDetailAsync(result))
}
function* actionGetPointsAccounts(data) {
  const response = yield call(asyncGetPointsAccounts, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Points } } = response.data;
      result.data = Points;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetPointsAccountsAsync(result))
}

//analytics
function* actionGetTotalCount(data) {
  const response = yield call(asyncGetTotalCount, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification } = response.data;
      result.data = Notification;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetTotalCountAsync(result))
}
function* actionGetGiftDayDonated(data) {
  const response = yield call(asyncGetGiftDayDonated, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Event_gifts_donated } } = response.data;
      result.data = Event_gifts_donated;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetGiftDayDonatedAsync(result))
}
function* actionGetPointGift(data) {
  const response = yield call(asyncGetPointGift, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Point_Gifts } } = response.data;
      result.data = Point_Gifts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetPointGiftAsync(result))
}
function* actionGetPointGiftImportToday(data) {
  const response = yield call(asyncGetPointGiftImportToday, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Point_Gifts } } = response.data;
      result.data = Point_Gifts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetPointGiftImportTodayAsync(result))
}
function* actionGetPointCustomer(data) {
  const response = yield call(asyncGetPointCustomer, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Event_point } } = response.data;
      result.data = Event_point;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetPointCustomerAsync(result))
}
function* actionGetGiftHourDonated(data) {
  const response = yield call(asyncGetGiftHourDonated, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Event_gifts } } = response.data;
      result.data = Event_gifts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetGiftHourDonatedAsync(result))
}
function* actionGetCustomers(data) {
  const response = yield call(asyncGetCustomers, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Customers } } = response.data;
      result.data = Customers;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetCustomersAsync(result))
}
function* actionDownloadCsvCustomers(data) {
  const response = yield call(asyncDownloadCsvCustomers, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Message } } = response.data;
      result.data = Message;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.DownloadCsvCustomersAsync(result))
}
function* actionGetPointsGifts(data) {
  const response = yield call(asyncGetPointsGifts, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Points_Gifts } } = response.data;
      result.data = Points_Gifts;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetPointsGiftsAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.EVENT_CREATE, actionCreate)
  yield takeLatest(TYPE.EVENT_LIST, actionList)
  yield takeLatest(TYPE.EVENT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.EVENT_DELETE, actionDelete)
  yield takeLatest(TYPE.EVENT_GET_DETAIL, actionGetDetail)
  yield takeLatest(TYPE.EVENT_GET_POINTS_ACCOUNTS, actionGetPointsAccounts)

  // analytics
  yield takeLatest(TYPE.EVENT_GET_TOTAL_COUNT, actionGetTotalCount)
  yield takeLatest(TYPE.EVENT_GET_GIFT_DAY_DONATED, actionGetGiftDayDonated)
  yield takeLatest(TYPE.EVENT_GET_POINT_GIFT, actionGetPointGift)
  yield takeLatest(TYPE.EVENT_GET_POINT_GIFT_IMPORT_TODAY, actionGetPointGiftImportToday)
  yield takeLatest(TYPE.EVENT_GET_POINT_CUSTOMER, actionGetPointCustomer)
  yield takeLatest(TYPE.EVENT_GET_GIFT_HOUR_DONATED, actionGetGiftHourDonated)
  yield takeLatest(TYPE.EVENT_GET_CUSTOMERS, actionGetCustomers)
  yield takeLatest(TYPE.EVENT_DOWNLOAD_CSV_CUSTOMERS, actionDownloadCsvCustomers)
  yield takeLatest(TYPE.EVENT_GET_POINTS_GIFTS, actionGetPointsGifts)
}

export default CustomSaga