import { combineReducers } from 'redux'
import { reducers as I18nReducers } from './I18n'
import { reducers as RealtimeReducers } from './Realtime'
import { reducers as AccountReducers } from './Account'
import { reducers as EventReducers } from './Event'
import { reducers as GiftReducers } from './Gift'
import { reducers as PointReducers } from './Point'
import { reducers as SupervisorReducers } from './Supervisor'

// Functions to catch actions dispatched
export default combineReducers({
    I18nReducers,
    RealtimeReducers,
    AccountReducers,
    EventReducers,
    GiftReducers,
    PointReducers,
    SupervisorReducers,
});