import moment from 'moment'

export const ParseDate = date => moment(date).format('YYYY-MM-DD')

export const ParseTime = date => moment(date).format('HH:mm')

export const GetDateTime = (time) => moment(time, "HH:mm").toDate();

export const GetTime = (time) => moment(time, "HH:mm").format('HH:mm');

export const NextweekDate = () => {
    let today = new Date();
    let nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
    return nextweek;
}

export const IsAfter = (date, dateToCompare) => moment(date).isAfter(dateToCompare);

export const CurrentTimeStamp = () => Math.round(+new Date()/1000)