const mapStateToProps = state => {
  return {
    i18n: state.I18nReducers,
    realtime: state.RealtimeReducers,
    accounts: state.AccountReducers,
    events: state.EventReducers,
    gifts: state.GiftReducers,
    points: state.PointReducers,
    supervisor: state.SupervisorReducers,
  };
};
export default mapStateToProps;