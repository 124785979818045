export const AddCommaNumber = (number) => {
    if (!number) return number;

    let negative = '';
    if (parseFloat(number) < 0) {
        negative = '-'
    }
    number = number.toString();
    return negative + number
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        ;
}

export const RefreshAuthorization = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("full_name");
    localStorage.removeItem("email");
    localStorage.removeItem("avatar_img_path");
    localStorage.removeItem("address");
    localStorage.removeItem("permissionName");
    localStorage.removeItem("z");

    // router
    document.location = '/';
}

const getPermission = () => {
    let permission = localStorage.getItem('z');
    if (!permission || permission === null || permission === "") {
        return undefined;
    } else {
        return permission.replace(/\//g, "").split('').reverse().join('');
    }
}

export const setPermission = (id, view_only) => {
    if (id == 1) {
        return "/v/n/";
    }
    if (id == 2) {
        return "/k/q/";
    }
    if (id == 3) {
        if (view_only === "true") {
            return "w/e/i/v/o/o/r/";
        }
        return "/o/o/r/";
    }
    return "/";
}

export const getPermissionCode = () => {
    let permission = getPermission();

    if (permission === "roo")
        return 3;
    if (permission === "qk")
        return 2;
    if (permission === "nv")
        return 1;
    return 0;
}

export const isPermissionRoot = () => {
    return getPermission() === "roo"
}

export const isPermissionRootView = () => {
    return getPermission() === "rooview"
}

export const isPermissionAdmin = () => {
    return getPermission() === "qk"
}

export const isPermissionUser = () => {
    return getPermission() === "nv"
}

export const isPermissionRootOrAdmin = () => {
    let permission = getPermission();
    return permission === "qk" || permission === "roo"
}

import { Url } from '../constants'
export const GetAvatar = path => (Url.END_POINT + "/" + path)
export const GetAvatarFromImageService = path => (Url.END_POINT_IMAGE + "/" + path)

export const GetCurrentDate = (momentDateTime) => {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Ho_Chi_Minh' };

    let curr = new Date().toLocaleDateString('vn-VN', options).split('/');
    if (momentDateTime) {
        curr = new Date(momentDateTime).toLocaleDateString('vn-VN', options).split('/');
    }

    let yyyy = curr[2]
    let mm = curr[0]
    let dd = curr[1]

    if (curr.length <= 1)
        return curr[0];

    return yyyy + "-" + mm + "-" + dd;
}
export const GetCurrentTime = (momentDateTime) => {
    var options = { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Ho_Chi_Minh' };

    let curr = new Date().toLocaleTimeString('vn-VN', options).split(':');
    if (momentDateTime) {
        curr = new Date(momentDateTime).toLocaleTimeString('vn-VN', options).split(':');
    }

    let HH = curr[0]
    let mm = curr[1]

    return HH + ":" + mm;
}

export const isValidateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    }
    return (false)
}

export const GetAllUsersUUIDFromGroup = ({ origin, data }) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
        let item = data[i];

        if (typeof (item) === 'number') {
            let point = origin.filter(p => p.value === item);
            let children = point[0].children;
            let uuids = children.map(u => u.value);

            if (uuids.length) {
                result = result.concat(uuids)
            }
        }
        else {
            result.push(item)
        }
    }
    return result;
}


export const GetAllUsersUUIDFromCheckPicker = ({ data }) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
        let item = data[i];

        if (typeof (item) === 'object') {
            result.push(item.value)
        }
        else {
            result.push(item)
        }
    }
    return result;
}

export const GetAllUsersObjectFromUUID = ({ users_uuid, data }) => {
    let result = [];

    if (typeof (users_uuid) === "string") {
        return data.find(user => user.value === users_uuid);
    }
    else if (typeof (users_uuid) === "object") {
        if (users_uuid) {
            for (let i = 0; i < users_uuid.length; i++) {
                let user_uuid = users_uuid[i];

                let item = data.find(user => user.value === user_uuid);
                result.push(item)
            }
        }
    }
    else { }

    return result;
}