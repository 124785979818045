import * as TYPE from './types'

const initialState = {
  data: [],
  detail: null,
  data_points_accounts: [],
  data_customers_link_csv: null,
  message: "",
  status: 500,

  // analytics
  data_total_count: null,
  data_gift_day: [],
  data_gift_hour: [],
  data_point_customer: [],
  data_point_gift: [],
  data_point_gift_import_today: [],
  data_customers: [],
  data_points_gifts: [],
}

function EventReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.EVENT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }

    // detail
    case TYPE.EVENT_GET_DETAIL_ASYNC:
      if (action.payload.data.uuid) {
        localStorage.setItem('event_uuid', action.payload.data.uuid)
      }
      
      return {
        ...state,
        detail: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    // points accounts
    case TYPE.EVENT_GET_POINTS_ACCOUNTS_ASYNC:
      return {
        ...state,
        data_points_accounts: action.payload.data,
      }

    //analytics
    //1
    case TYPE.EVENT_GET_TOTAL_COUNT_ASYNC:
      return {
        ...state,
        data_total_count: action.payload.data,
      }
    //2
    case TYPE.EVENT_GET_GIFT_DAY_DONATED_ASYNC:
      return {
        ...state,
        data_gift_day: action.payload.data,
      }
    //3.1
    case TYPE.EVENT_GET_POINT_GIFT_ASYNC:
      return {
        ...state,
        data_point_gift: action.payload.data,
      }
    //3.3
    case TYPE.EVENT_GET_POINT_GIFT_IMPORT_TODAY_ASYNC:
      return {
        ...state,
        data_point_gift_import_today: action.payload.data,
      }
    //3.2 - 4 - 5
    case TYPE.EVENT_GET_POINT_CUSTOMER_ASYNC:
      return {
        ...state,
        data_point_customer: action.payload.data,
      }
    //6
    case TYPE.EVENT_GET_GIFT_HOUR_DONATED_ASYNC:
      return {
        ...state,
        data_gift_hour: action.payload.data,
      }
    //7
    case TYPE.EVENT_GET_CUSTOMERS_ASYNC:
      return {
        ...state,
        data_customers: action.payload.data,
      }
    case TYPE.EVENT_DOWNLOAD_CSV_CUSTOMERS_ASYNC:
      return {
        ...state,
        data_customers_link_csv: action.payload.data,
      }
    //8
    case TYPE.EVENT_GET_POINTS_GIFTS_ASYNC:
      return {
        ...state,
        data_points_gifts: action.payload.data,
      }
    default:
      return state
  }
}

export default EventReducer