// CRUD
export const POINT_CREATE = "POINT_CREATE"
export const POINT_CREATE_ASYNC = "POINT_CREATE_ASYNC"
export const POINT_UPDATE = "POINT_UPDATE"
export const POINT_UPDATE_ASYNC = "POINT_UPDATE_ASYNC"
export const POINT_LIST = "POINT_LIST"
export const POINT_LIST_ASYNC = "POINT_LIST_ASYNC"
export const POINT_DELETE = "POINT_DELETE"
export const POINT_DELETE_ASYNC = "POINT_DELETE_ASYNC"

// DETAIL
export const POINT_GET_DETAIL = "POINT_GET_DETAIL"
export const POINT_GET_DETAIL_ASYNC = "POINT_GET_DETAIL_ASYNC"

export const POINT_UNMOUNT = "POINT_UNMOUNT"
export const POINT_UNMOUNT_ASYNC = "POINT_UNMOUNT_ASYNC"