// CRUD
export const GIFT_MANAGER_LIST = "GIFT_MANAGER_LIST"
export const GIFT_MANAGER_LIST_ASYNC = "GIFT_MANAGER_LIST_ASYNC"

export const EVENT_MANAGER_GET_CUSTOMERS = "EVENT_MANAGER_GET_CUSTOMERS"
export const EVENT_MANAGER_GET_CUSTOMERS_ASYNC = "EVENT_MANAGER_GET_CUSTOMERS_ASYNC"
export const EVENT_MANAGER_GET_ACCOUNTS_STAFF = "EVENT_MANAGER_GET_ACCOUNTS_STAFF"
export const EVENT_MANAGER_GET_ACCOUNTS_STAFF_ASYNC = "EVENT_MANAGER_GET_ACCOUNTS_STAFF_ASYNC"
export const EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS = "EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS"
export const EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS_ASYNC = "EVENT_MANAGER_DOWNLOAD_CSV_CUSTOMERS_ASYNC"
