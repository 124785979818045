import * as TYPE from './types'

const dispatchInitRealtime = () => ({
  type: TYPE.INIT_REALTIME,
})
const dispatchUpdateRealtime = () => ({
  type: TYPE.UPDATE_REALTIME,
})

export default {
  dispatchInitRealtime,
  dispatchUpdateRealtime,
};