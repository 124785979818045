import * as TYPE from './types'

const initialState = {
  data: [],
  data_by_user: [],
  data_gift_spin_count: [],
  is_submit_success: false,
  avaliable: [],
  message: "",
  status: 500,
}

function GiftReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.GIFT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.GIFT_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.GIFT_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.GIFT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }

    //  avaliable
    case TYPE.GIFT_LIST_AVALIABLE_ASYNC:
      return {
        ...state,
        avaliable: action.payload.data,
      }
    //  by user
    case TYPE.GIFT_LIST_BY_USER_ASYNC:
      return {
        ...state,
        data_by_user: action.payload.data,
      }
    //  success submit
    case TYPE.GIFT_SUCCESS_SUBMIT_ASYNC:
      return {
        ...state,
        is_submit_success: true,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.GIFT_SPIN_COUNT_BY_USER_ASYNC:
      return {
        ...state,
        is_submit_success: false,
        data_gift_spin_count: action.payload.data,
        message: "",
        status: 500,
      }
    default:
      return state
  }
}

export default GiftReducer