import { all } from 'redux-saga/effects'

import { default as AccountSaga } from './Account/sagas';
import { default as EventSaga } from './Event/sagas';
import { default as GiftSaga } from './Gift/sagas';
import { default as PointSaga } from './Point/sagas';
import { default as SupervisorSaga } from './Supervisor/sagas';

export default function* rootSaga() {
    yield all([
       AccountSaga(),
       EventSaga(),
       GiftSaga(),
       PointSaga(),
       SupervisorSaga(),
    ])
}
