import React, { Component } from "react"
import Toolbar from '../components/toolbar/Toolbar';
import { withRouter } from "react-router-dom";
import { Container, Content } from 'rsuite'
import ErrorBoundary from '../components/common/ErrorBoundary'

class UserLayout extends Component {
  render() {
    return (
      <div id="app-container">
        <header className="toolbar">
          <Toolbar />
        </header>
        <div className="body-overview min-h-500">
          <ErrorBoundary>
            <Container>
              <Content>
                {this.props.children}
              </Content>
            </Container>
          </ErrorBoundary>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default withRouter(UserLayout)