import React, { Component } from "react"
import DropzoneComponent from "react-dropzone-component"
import { Url } from '../../constants'
var ReactDOMServer = require('react-dom/server')

export default class DropzoneContainer extends Component {
  render() {
    var dropzoneComponentConfig = {
      postUrl: `${Url.END_POINT}/api/upload-image-react`
    };
    var dropzoneConfig = {
      params: {
        folder: `${this.props.folder}`
      },
      iconFiletypes: ['.jpg', '.png', '.gif', '.jpeg'],
      thumbnailHeight: 80,
      acceptedFiles: '.jpg, .png, .gif, .jpeg',
      maxFiles: this.props.maxFiles,
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview mb-3">
          <div className="d-flex flex-row ">
            <div className="p-0 w-30 position-relative">
              <div className="dz-error-mark">
                <span>
                  <i />{" "}
                </span>
              </div>
              <div className="dz-success-mark">
                <span>
                  <i />
                </span>
              </div>
              <div className="preview-container">
                {/*  eslint-disable-next-line jsx-a11y/alt-text */}
                <img data-dz-thumbnail className="img-thumbnail border-0" />
                <i className="simple-icon-doc preview-icon" />
              </div>
            </div>
            <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
              <div>
                {" "}
                <span data-dz-name />{" "}
              </div>
              <div className="text-primary text-extra-small" data-dz-size />
              <div className="dz-progress">
                <span className="dz-upload" data-dz-uploadprogress />
              </div>
              <div className="dz-error-message">
                <span data-dz-errormessage />
              </div>
            </div>
          </div>
          <a href="#/" className="remove" data-dz-remove>
            {" "}
            <i className="glyph-icon simple-icon-trash" />{" "}
          </a>
        </div>
      ),
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
      }
    };
    var eventHandlers = {
      success: function (file, response, error) {
        let result = localStorage.getItem("uploads");
        let original = response.Notification.Image;
        if (result) {
          original = original.concat('|').concat(result);
        }
        localStorage.setItem("uploads", original);
      },
      removedfile: function (file, response, error) {
        if (file.xhr) {
          let fileParse = JSON.parse(file.xhr.responseText);

          const { Notification: { Image } } = fileParse
          if (!Image)
            return;

          let result = localStorage.getItem("uploads");
          let resultParse = result.split('|');
          let newResult = [];
          let len = resultParse.length;

          for (let i = 0; i < len; i++) {
            if (resultParse[i] != Image) {
              newResult.push(resultParse[i])
            }
          }
          localStorage.setItem("uploads", newResult.join('|'));
        }
      }
    };

    return (
      <DropzoneComponent
        config={dropzoneComponentConfig}
        djsConfig={dropzoneConfig}
        eventHandlers={eventHandlers}
      />
    );
  }
}
