// CRUD
export const GIFT_CREATE = "GIFT_CREATE"
export const GIFT_CREATE_ASYNC = "GIFT_CREATE_ASYNC"
export const GIFT_UPDATE = "GIFT_UPDATE"
export const GIFT_UPDATE_ASYNC = "GIFT_UPDATE_ASYNC"
export const GIFT_LIST = "GIFT_LIST"
export const GIFT_LIST_ASYNC = "GIFT_LIST_ASYNC"
export const GIFT_DELETE = "GIFT_DELETE"
export const GIFT_DELETE_ASYNC = "GIFT_DELETE_ASYNC"

// AVALIABLE
export const GIFT_LIST_AVALIABLE = "GIFT_LIST_AVALIABLE"
export const GIFT_LIST_AVALIABLE_ASYNC = "GIFT_LIST_AVALIABLE_ASYNC"

// by user
export const GIFT_LIST_BY_USER = "GIFT_LIST_BY_USER"
export const GIFT_LIST_BY_USER_ASYNC = "GIFT_LIST_BY_USER_ASYNC"

export const GIFT_SPIN_COUNT_BY_USER = "GIFT_SPIN_COUNT_BY_USER"
export const GIFT_SPIN_COUNT_BY_USER_ASYNC = "GIFT_SPIN_COUNT_BY_USER_ASYNC"

export const GIFT_SUCCESS_SUBMIT = "GIFT_SUCCESS_SUBMIT"
export const GIFT_SUCCESS_SUBMIT_ASYNC = "GIFT_SUCCESS_SUBMIT_ASYNC"