import * as TYPE from './types'

// CRUD
const dispatchGiftCreateAsync = (data) => ({
  type: TYPE.GIFT_CREATE,
  data,
});
const dispatchGiftListAsync = (data) => ({
  type: TYPE.GIFT_LIST,
  data,
});
const dispatchGiftUpdateAsync = (data) => ({
  type: TYPE.GIFT_UPDATE,
  data,
});
const dispatchGiftDeleteAsync = (data) => ({
  type: TYPE.GIFT_DELETE,
  data,
});

// avaliable
const dispatchGiftListAvaliableAsync = (data) => ({
  type: TYPE.GIFT_LIST_AVALIABLE,
  data,
});

// list by user
const dispatchGiftListByUserAsync = (data) => ({
  type: TYPE.GIFT_LIST_BY_USER,
  data,
});

// submit success
const dispatchGiftSuccessSubmitAsync = (data) => ({
  type: TYPE.GIFT_SUCCESS_SUBMIT,
  data,
})

const dispatchGiftSpinCountByUserAsync = (data) => ({
  type: TYPE.GIFT_SPIN_COUNT_BY_USER,
  data,
});

export default {
  dispatchGiftCreateAsync,
  dispatchGiftListAsync,
  dispatchGiftUpdateAsync,
  dispatchGiftDeleteAsync,
  
  dispatchGiftListAvaliableAsync,
  dispatchGiftListByUserAsync,
  dispatchGiftSuccessSubmitAsync,
  dispatchGiftSpinCountByUserAsync,
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.GIFT_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.GIFT_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.GIFT_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.GIFT_DELETE_ASYNC,
    payload
  }
}

// avaliable
export const ListAvaliableAsync = (payload) => {
  return {
    type: TYPE.GIFT_LIST_AVALIABLE_ASYNC,
    payload
  }
} 

// list by user
export const ListByUserAsync = (payload) => {
  return {
    type: TYPE.GIFT_LIST_BY_USER_ASYNC,
    payload
  }
} 

// list by user
export const SuccessSubmitAsync = (payload) => {
  return {
    type: TYPE.GIFT_SUCCESS_SUBMIT_ASYNC,
    payload
  }
} 
export const SpinCountByUserAsync = (payload) => {
  return {
    type: TYPE.GIFT_SPIN_COUNT_BY_USER_ASYNC,
    payload
  }
} 